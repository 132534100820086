import styled, { createGlobalStyle } from "styled-components";
import * as colors from "../config/colors";
import "react-toastify/dist/ReactToastify.css";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
  }

  body {
    font-family: sans-serif;
    background: ${colors.backgroundColorCompany};
    color: ${colors.primaryDarkColor};
  }

  html, body, #root {
    height: 100%;
  }

  button, a { /* Agrupando estilos de botões e links */
    cursor: pointer;
    background: ${colors.primaryColor};
    color: ${colors.textColor}; /* Mesma cor para ambos */
    padding: 10px 20px;
    border: 2px solid ${colors.textColor}; /* Certifique-se de aplicar a borda */
    border-radius: 6px;
    font-weight: 700;
    transition: all 300ms;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-decoration: none; /* Para garantir que links fiquem consistentes */

    &:hover {
      filter: brightness(85%);
    }
  }
  
  ul {
    list-style: none;
  }

  .img-button {
    cursor: pointer;
    /* background: none;
    border: none; */
    padding: 0;
    max-width: 100%;
    height: auto;
    border: 1px solid #fff;

  }

  .newItem {
    margin-top: 12px;
    background:  none;
    border: 1px solid #000;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
  }
  
  img {
    padding: 10px;
    max-width: 100%;
    height: auto;
  }

  .itemobs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  
  }

  .input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 10;
    overflow: hidden; 
    position: absolute;
    z-index: -1;
  }

  .input-file + label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: black;
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
  }

  .input-file:focus + label, .input-file + label:hover {
    background-color: red;
  }

  .input-file { 
  display: none;
  }

  .custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    cursor: pointer;
    background: ${colors.primaryColor};
    color: #fff;
    padding: 5px;
    border-radius: 6px;
    font-weight: 700;
    transition: all 300ms;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 10px;
  }

`;

/* body .Toastify .Toastify__toast-container .toastify__toats--success {
    background: ${colors.sucessColor}
  }

  body .Toastify .Toastify__toast-container .toastify__toats--error {
    background: ${colors.sucessColor}
  } */

export const Container = styled.section`
  max-width: 480px;
  background: #fff;
  margin: 30px auto;
  padding: 30px;
  border-radius: 6px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) */
  border: 2px solid #000;
`;

export const HeroSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  padding: 40px;
  background: ${colors.backgroundColorCompany};

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const RightColumn = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 0px 10px 50px 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  margin: 20px;
  max-height: 95vh; /* Aumentamos um pouco a altura */
  overflow-y: auto; /* Mantém a rolagem quando necessário */

  @media (max-width: 768px) {
    width: 100%;
    margin: 20px 0;
    max-height: none; /* Remove a altura fixa em telas menores */
  }

  .DateTitle {
    padding-top: 40px;
    font-weight: bold;
    font-size: 18px;
  }

  .filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .filter-container button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 10px;
    cursor: pointer;
  }

  .filter-container span {
    font-size: 16px;
    font-weight: bold;
    padding: 0 10px;
  }
`;

export const LeftColumn = styled.div`
  flex: 1;
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  margin: 20px;
  display: flex;
  flex-direction: column;
  
  /* Remove altura fixa para permitir crescimento conforme o conteúdo */
  h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 20px 0;
  }

  .schedule-btn {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s;

    margin: 0 auto; /* Centraliza horizontalmente */
    display: block; /* Garante que o elemento respeite o margin */
    text-align: center; /* Centraliza o texto dentro do botão */

    &:hover {
      background-color: #0056b3;
    }
  }

`;
